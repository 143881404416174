import { AxiosInstance } from 'axios';
import { apiUrlV4 } from 'src/services/constants/api-versions';

import { ApiResponse } from './types';

export enum GameStatusType {
  Live = 'live',
  Upcoming = 'upcoming',
  Final = 'final',
}

interface ApiConnectionType {
  _id: string;
  name: string;
}

interface ApiGameStatus {
  _id: string;
  name: GameStatusType;
  display_name: string;
  is_games_admin: boolean;
}

interface ApiLastUpdate {
  _id: string;
  name: string;
  display_name: string;
}

export interface ConnectionType {
  id: string;
  name: string;
}

export interface GameStatus {
  id: string;
  name: GameStatusType;
  displayName: string;
  isGamesAdmin: boolean;
}

export interface LastUpdate {
  id: string;
  name: string;
  displayName: string;
}

export interface Shadow {
  id: string;
  scoreboardType: string;
  displayName: string;
  manufacturer: string;
  model: string;
  sports: string[];
  shadow: string;
}

const mapApiConnectionType = (
  apiConnectionType: ApiConnectionType
): ConnectionType => {
  return {
    id: apiConnectionType._id,
    name: apiConnectionType.name,
  };
};

const mapApiGameStatus = (apiGameStatus: ApiGameStatus): GameStatus => {
  return {
    id: apiGameStatus._id,
    name: apiGameStatus.name,
    displayName: apiGameStatus.display_name,
    isGamesAdmin: apiGameStatus.is_games_admin,
  };
};

const mapApiLastUpdate = (apiLastUpdate: ApiLastUpdate): LastUpdate => {
  return {
    id: apiLastUpdate._id,
    name: apiLastUpdate.name,
    displayName: apiLastUpdate.display_name,
  };
};

export const FiltersService = (axios: AxiosInstance) => {
  const getConnectionTypes = async () => {
    const res = await axios.get<ApiResponse<ApiConnectionType[]>>(
      `${apiUrlV4}device/connected-by`
    );
    return res.data.data.map(mapApiConnectionType);
  };

  const getPeriodValues = async () => {
    const res = await axios.get<ApiResponse<number[]>>(
      `${apiUrlV4}gamesAdmin/periodValues`
    );
    return res.data.data;
  };

  const getGameStatuses = async () => {
    const search = new URLSearchParams({ is_games_admin: '1' });
    const res = await axios.get<ApiResponse<ApiGameStatus[]>>(
      `${apiUrlV4}gamesAdmin/gameStatuses?${search.toString()}`
    );
    return res.data.data.map(mapApiGameStatus);
  };

  const getLastUpdates = async () => {
    const res = await axios.get<ApiResponse<ApiLastUpdate[]>>(
      `${apiUrlV4}gamesAdmin/gameLastUpdate`
    );
    return res.data.data.map(mapApiLastUpdate);
  };

  return {
    getConnectionTypes,
    getPeriodValues,
    getGameStatuses,
    getLastUpdates,
  };
};
